import React from 'react';
import styled from 'styled-components';

import { colors } from 'src/theme/colors';
import Heading, { HeadingVariant, HeadingScope } from 'components/Heading';

const Card = styled.div`
  align-items: center;
  background-color: ${colors.green300}20;
  column-gap: 24px;
  display: flex;
  max-width: 456px;
  padding: 16px 24px;
`;

const IconContainer = styled.div`
  flex: 0 0 24px;
  height: auto;
  width: 24px;
`;

const Content = styled.div`
  margin: 8px 0 0;
`;

interface NotificationCardProps {
  icon: React.ReactNode;
  heading: string;
  children: React.ReactNode;
}

const NotificationCard: React.FC<NotificationCardProps> = ({
  icon,
  heading,
  children,
}) => {
  return (
    <Card>
      <IconContainer>{icon}</IconContainer>
      <HeadingScope>
        <div>
          <Heading variant={HeadingVariant.DELTA}>{heading}</Heading>
          <Content>{children}</Content>
        </div>
      </HeadingScope>
    </Card>
  );
};

export default NotificationCard;
