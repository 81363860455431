import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import SEO from 'components/SEO';
import Layout from 'components/Layout';
import Content from 'components/Content';
import SectionTitle from 'components/SectionTitle';
import Note, { NoteVariant } from 'components/Note';
import FixedWidthCol from 'components/FixedWidthCol';
import { HeadingScope } from 'components/Heading';
import { Hero } from './product-information-import';
import NotificationCard from 'components/NotificationCard';
import { CheckmarkIcon, CrossIcon } from 'src/assets/icons';
import { device } from 'src/theme/breakpoints';

const Text = styled.p`
  margin: 36px 0 0;
`;

const NotesWrapper = styled.div`
  align-items: center;
  column-gap: 24px;
  display: flex;
  row-gap: 16px;
  margin-top: 32px;

  @media ${device.mobileDOWN} {
    flex-direction: column;
  }
`;

const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  & path {
    stroke-width: 3px;
  }
`;

const PCMPage: FC = () => {
  const { t } = useTranslation();

  return (
    <Layout footerHasMargin>
      <Hero
        image={
          <StaticImage
            src="../assets/images/features/product-category-mapping/pcm-main.jpg"
            alt={t('pcm_heading')}
            layout="fullWidth"
          />
        }
        title={t('pcm_heading')}
        text={t('pcm_text')}
      />
      <HeadingScope>
        <Content>
          <FixedWidthCol>
            <SectionTitle subtitle={t('pcm_benefits_subtitle')}>
              <Trans i18nKey="pcm_benefits_title" />
            </SectionTitle>
            <Note variant={NoteVariant.ALFA}>
              <p>
                <Trans i18nKey="pcm_benefits_note" />
              </p>
            </Note>
            <Text>
              <Trans i18nKey="pcm_benefits_text" />
            </Text>
            <SectionTitle subtitle={t('pcm_compare_subtitle')}>
              <Trans i18nKey="pcm_compare_title" />
            </SectionTitle>
            <Note variant={NoteVariant.BRAVO}>
              <Trans i18nKey="pcm_compare_text" />
            </Note>
            <NotesWrapper>
              <NotificationCard
                heading={t('pcm_compare_1_title')}
                icon={<StyledCheckmarkIcon />}
              >
                <Trans i18nKey="pcm_compare_1_text_0" />
                <strong>
                  <Trans i18nKey="pcm_compare_1_text_1" />
                </strong>
              </NotificationCard>
              <NotificationCard
                heading={t('pcm_compare_2_title')}
                icon={<CrossIcon />}
              >
                <Trans i18nKey="pcm_compare_2_text_0" />
                <strong>
                  <Trans i18nKey="pcm_compare_2_text_1" />
                </strong>
              </NotificationCard>
            </NotesWrapper>
          </FixedWidthCol>
        </Content>
      </HeadingScope>
    </Layout>
  );
};

export default PCMPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => (
  <SEO
    title="Product Category Mapping | OmniGet"
    description="Map and categorize your products effortlessly with OmniGet. Enhance your product organization and improve navigation for a better user experience."
  />
);
